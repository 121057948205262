﻿.validation-summary {
    margin-bottom: 8px;
    padding: 8px;
    background-color: rgba(255,0,0,0.33);
    border-radius: 4px;

    ul {
        margin: 4px 0;
        padding-left: 16px;
    }

    li {
        display: block;
    }
}
