@import '../../styles/themes';
@import '../../styles/validation-summary';

@mixin nc-form-center-theme($theme) {
    .form-container {
        color: map-get($theme, on-background);
    }

    .content-wrapper {
        background-color: map-get($theme, background);
    }

    .global-banner {
        background-image: if(map-get($theme, is-dark), url("../bg-white.png"), url("../bg-dark.png"));
        animation-name: background;
        animation-duration: 90s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-size: auto 100%;
    }

    .nomadis-logo {
        .icon-nmd-theme {
            fill: if(map-get($theme, is-dark), #000, #F4F4F4);
        }
    }
}

@keyframes background {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -2363px;
    }
}

@media screen and (min-width: 1140px) {
    .global-wrapper {
        display: flex;
        flex-direction: row;
        min-height: 550px;
        height: 100%;
    }

    .global-banner {
        height: 100vh;
        width: 50%;
        min-width: 50%;
        flex-shrink: 1;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 550px;
    }

    .content-wrapper {
        width: 100%;
        flex-shrink: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .form-container {
        width: 100%;
        padding: 0 25% 0 10%;
        min-width: calc(250px + 35%);
        max-height: 100%;
        overflow-y: auto;
    }
}
@media screen and (max-width: 1139.98px) {
    .global-banner {
        padding: 0;
        height: 20vh;

        .nomadis-logo svg {
            height: 10vh;
        }
    }

    #selected-organization-chip {
        position: absolute;
        top: 21.5vh;
        left: 1.5vh;
        background-color: lightgray;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        padding: 8px;

        img {
            width: 32px;
            height: 32px;
        }
    }

    #language-dropdown {
        top: 21.5vh;
        right: 1.5vh;
        
        .label-container {
            display: none;
        }

        .mdc-select__anchor {
            height: 40px;
        }

        .material-icons {
            padding-top: 8px;
        }
    }

    .content-wrapper {
        padding: 60px 5%;
        min-width: 300px;
    }

    h1 {
        font-size: 56px !important;
        margin-bottom: 37.52px !important;
    }

    .mdc-select__anchor {
        width: 36px;
    }
}

.mdc-tab-bar {
    margin-bottom: 64px;
}

.form-container > form { 
    max-width: 600px;
}

.nomadis-logo {
    vertical-align: middle;
    text-align: center;

    svg {
        max-width: 100%;
        width: 420px;
        height: 150px;
    }

    .icon-nmd-color {
        fill: #f26227;
    }
}

h1 {
    font-size: 77px;
    font-weight: bold;
    margin-bottom: 50px;
}

@media screen and (max-width: 720px) {
    h1 {
        font-size: 2.5rem !important;
    }

    .font-large {
        font-size: 1.4rem;
    }

    .font-medium {
        font-size: 1.2rem;
    }

    .form-container {
        padding: 0 5%;
    }
}

@include nc-form-center-theme($theme-light);

.theme-dark {
    @include nc-form-center-theme($theme-dark);
}
